<template>
  <div>
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <div class="row breadcrumbs-top">
          <h2 class="content-header-title float-start mb-0">Villa Assign</h2>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-2">
        <div class="col-12">
          <div class="breadcrumb-wrapper">
            <ol class="breadcrumb float-end">
              <li class="breadcrumb-item">
                <router-link to="/dashboard">Go to Dashboard</router-link>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <section id="dashboard-ecommerce">
        <div class="row match-height">
          <div class="col-xl-12 col-md-12 col-12">
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="border p-1">
                  <div class="row">
                    <div class="col-12">
                      <table
                        class="dt-column-search table table-responsive border"
                      >
                        <thead>
                          <tr>
                            <th colspan="10">
                              <h4 class="mb-0">Booking Details</h4>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td class="title">
                              <span class="heading">Check In</span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                            </td>
                            <td>{{ response?.check_in_date }}</td>
                          </tr>
                          <tr>
                            <td class="title">
                              <span class="heading">Check Out</span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                            </td>
                            <td>{{ response?.check_out_date }}</td>
                          </tr>
                          <tr>
                            <td class="title">
                              <span class="heading">Amount</span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                            </td>
                            <td>
                              <span class="rupee">₹</span
                              >{{
                                parseFloat(response?.final_amount || 0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}.00
                            </td>
                          </tr>
                          <tr>
                            <td class="title">
                              <span class="heading">Payment Mode</span>
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:
                            </td>
                            <td>Phone Pay</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-congratulation-medal">
              <div class="card-body">
                <div class="border p-1">
                  <div class="row">
                    <div
                      class="col-4"
                      v-for="(item, index) in response.villa_name"
                      :key="index"
                    >
                      <h4 class="card-header">
                        <span
                          >{{ item.text }} &nbsp;&nbsp;(
                          {{ item.villa_qty }} )</span
                        >
                        <span
                          ><button
                            class="btn btn-sm villa_add"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModalCenter"
                            @click="
                              villaDeside(
                                item.id,
                                response?.check_in_date,
                                response?.check_out_date
                              )
                            "
                            :disabled="
                              item.villa_qty <= item.assigned_villa.length
                            "
                          >
                            <vue-feather
                              type="plus-circle"
                            ></vue-feather></button
                        ></span>
                      </h4>
                      <div
                        v-if="item.assigned_villa && item.assigned_villa.length"
                      >
                        <table
                          class="dt-column-search table table-responsive border villa_list"
                          v-for="(
                            assigned_item, assigned_index
                          ) in item.assigned_villa"
                          :key="assigned_index"
                        >
                          <thead>
                            <tr>
                              <th colspan="10">
                                <h4 class="mb-0 villa_number">
                                  <span
                                    ><b
                                      >Villa No :
                                      {{ assigned_item.villa_number }}</b
                                    ></span
                                  >
                                  <span
                                    ><button
                                      class="btn btn-sm villa_remove"
                                      @click="
                                        removeVilla(
                                          index,
                                          assigned_index,
                                          assigned_item
                                        )
                                      "
                                    >
                                      <vue-feather
                                        type="x-circle"
                                      ></vue-feather></button
                                  ></span>
                                </h4>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(
                                person, person_index
                              ) in assigned_item.persons"
                              :key="person_index"
                            >
                              <td class="user_detail name">
                                {{ person.name }}
                                <span
                                  v-if="person.is_primary"
                                  class="is_primary"
                                  >p</span
                                >
                              </td>
                              <td class="user_detail">{{ person.mobile }}</td>
                              <td class="user_detail file">
                                <a
                                  :href="person.proof"
                                  :class="{ disabled: !person.proof }"
                                  target="_blank"
                                >
                                  <vue-feather
                                    type="file"
                                    class="proof text-primary"
                                  ></vue-feather>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else>
                        <table
                          class="dt-column-search table table-responsive border villa_list"
                        >
                          <tbody>
                            <tr>
                              <td class="user_detail">
                                <i>No Villa Assigned</i>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div
      class="modal fade"
      id="exampleModalCenter"
      tabindex="-1"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
      ref="popup"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
              Select Villa
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div v-if="villa_options.length">
              <Select2
                v-model="villa_option"
                :options="villa_options"
                :settings="{ placeholder: 'Choose Villa' }"
              />
              <div
                class="invalid-feedback fw-bold fst-italic"
                :class="{ 'd-block': errors.length && errors[0].model }"
                v-if="errors.length && errors[0].model"
              >
                {{ errors[0].model }}
              </div>
            </div>
            <div
              class="demo-inline-spacing text-center justify-content-center"
              v-else
            >
              <div class="spinner-grow text-primary me-1" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              :disabled="!villa_option"
              @click="addVilla()"
            >
              Add
            </button>
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Select2 from "vue3-select2-component";

export default {
  name: "VillaAssign",
  components: { Select2 },
  data() {
    return {
      id: this.$route.params.booking_id,
      token: localStorage.getItem("auth_token"),
      response: {},
      villa_options: [],
      villa_option: null,
      errors: [],
      isShow: "show",
    };
  },
  async mounted() {
    this.getBookingDetail();
  },
  methods: {
    async getBookingDetail() {
      await axios
        .get(process.env.VUE_APP_API_URL + "/villa-show/" + this.id, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          if (response.data) {
            this.response = response.data.data;
          }
        });
    },
    async villaDeside(id, check_in_date, check_out_date) {
      this.errors = [];
      this.villa_option = null;
      await axios
        .post(
          process.env.VUE_APP_API_URL + "/villa-deside",
          {
            id: id,
            check_in_date: check_in_date,
            check_out_date: check_out_date,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          const villa_options = [];
          const villa_list = this.response.villa_name;
          const objIndex = villa_list.findIndex((x) => x.id == id);
          const used_villa = [];

          if (
            villa_list[objIndex] &&
            villa_list[objIndex].assigned_villa.length
          ) {
            villa_list[objIndex].assigned_villa.forEach((element) => {
              used_villa.push(element.villa_id);
            });
          }

          response.data[0].forEach((element) => {
            if (!used_villa.includes(element.id)) {
              villa_options.push({
                id: element.id,
                text: element.number,
                villa_type_id: element.villa_type_id,
              });
            }
          });
          this.villa_options = villa_options;
        });
    },
    async addVilla() {
      this.errors = [];
      if (!this.villa_option) {
        this.errors.push({
          model: "Choose the villa required!",
        });
        return false;
      }

      const selected = this.villa_options.find(
        (x) => x.id == parseInt(this.villa_option)
      );
      const villa_list = this.response.villa_name;
      const objIndex = villa_list.findIndex(
        (x) => x.id == selected.villa_type_id
      );   

      if (
        villa_list[objIndex].villa_qty <=
        villa_list[objIndex].assigned_villa.length
      ) {
        this.errors.push({
          model: `${villa_list[objIndex].text} is required only ${villa_list[objIndex].villa_qty} villa`,
        });
        return false;
      }

      await axios
        .post(
          process.env.VUE_APP_API_URL +
            "/villa-submit/" +
            parseInt(this.villa_option),
          {
            booking_id: parseInt(this.id),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 201) {
            this.$toast.success(response.data.message);

            const persons = [];
            if (response.data.data.length) {
              response.data.data.forEach((element) => {
                persons.push({
                  id: element.id,
                  booking_id: element.booking_id,
                  villa_id: element.villa_id,
                  mobile: element.mobile,
                  name:
                    element.first_name +
                    (element.last_name ? " " + element.last_name : ""),
                  proof: element.proof,
                  is_primary: element.is_primary,
                });
              });
            }

            if (objIndex >= 0) {
              villa_list[objIndex].assigned_villa.push({
                villa_type_id: villa_list[objIndex].id,
                villa_id: selected.id,
                villa_number: selected.text,
                persons: persons,
              });
            }
            const remove_option_index = this.villa_options.indexOf(selected);
            if (remove_option_index > -1) {
              this.villa_options.splice(remove_option_index, 1);
              this.villa_option = null;
            }
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
    async removeVilla(index, assigned_index, assigned_item) {
      const villa_list = this.response.villa_name;

      await axios
        .post(
          process.env.VUE_APP_API_URL + "/villa-assign-remove",
          {
            booking_id: parseInt(this.id),
            villa_type_id: parseInt(assigned_item.villa_type_id),
            villa_id: parseInt(assigned_item.villa_id),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 201) {
            this.$toast.success(response.data.message);
            if (assigned_index > -1) {
              villa_list[index].assigned_villa.splice(assigned_index, 1);
            }
          } else {
            this.$toast.error(response.data.error?.[0]);
          }
        })
        .catch((error) => {
          this.$toast.error(error);
        });
    },
  },
};
</script>
<style scoped>
.table-responsive tbody td.title {
  width: 250px;
}
.table-responsive tbody td.title .heading {
  width: 150px;
  display: inline-block;
  font-weight: 600;
}
.card-header {
  background: #7da642;
  color: #fff;
  border-radius: unset;
  padding: 1rem 1rem;
}

.btn.villa_add,
.villa_remove {
  padding: 0;
}
.btn.villa_add .vue-feather {
  color: #fff;
}

.btn.villa_remove .vue-feather {
  color: #dc3545;
}

.villa_number {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  text-transform: capitalize;
}

.villa_list {
  margin-top: 20px;
}

.villa_list thead tr th,
.villa_list tbody tr td {
  padding: 0.72rem 13px;
}

.villa_list th,
.villa_list td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

/* .villa_list .user_detail {
  display: flex;
  align-items: center;
} */

.villa_list .user_detail .proof {
  width: 15px;
  margin-left: 3px;
  transition: all 0.5s;
}

.villa_list .user_detail.file {
  cursor: pointer;
}

.villa_list .user_detail.file:hover .proof {
  transform: scale(1.3);
}

.villa_list .user_detail.name {
  text-transform: capitalize;
  width: 50%;
}

.villa_list .user_detail.file {
  text-align: right;
  padding-right: 18px !important;
  padding-top: 10px !important;
}

.modal-footer {
  justify-content: center;
}

.vue-feather.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.is_primary {
  width: 18px;
  height: 18px;
  display: inline-flex;
  background: #343a40;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 50%;
  font-size: 12px;
}
</style>
